import React, {useEffect, Fragment} from 'react'
import {Link} from '@reach/router'
import {
  SVG,
  Pressable,
  Card,
  Transition,
  Tab,
  twMerge,
  createFormatterFn,
  HTML,
  Timestamp,
  Divider,
} from '@quotefactory/ui'
import {useSelector, useDispatch} from 'react-redux'
import {fetchAccount, selectAccountById, updateAccount} from 'reducers/account'
import {updateUser, selectUser} from 'reducers/user'
import {Layout} from 'pages'
import {ProviderIntegrationList} from 'components'
import {
  AccountUsers,
  AccountIntegrationList,
  AccountSettings,
  AccountFeatureFlags,
  AccountBillingContact,
  AccountPathfinderCard,
} from 'components/account'
import {QFIconWithCopy} from 'components/ui'
import CreditLimitChart from 'components/account/CreditChart'

import {capitalize, addressToString} from 'helpers'
import {
  useGetLinkedCustomerQuery,
  useGetProviderByIdentifierQuery,
  useGetCloseIOQuery,
} from 'services/rtk-api'

export const formatAsUSDsansDecimals = createFormatterFn({
  locale: 'en-US',
  currency: 'USD',
  decimalPlaces: 0,
})

export default function Account({accountId}) {
  const isDev = process.env.NODE_ENV !== 'production'

  const [statusExpanded, setStatusExpanded] = React.useState(false)

  const dispatch = useDispatch()
  const account =
    useSelector((state) => selectAccountById(state, accountId)) || {}

  const user = useSelector(selectUser)

  const {
    name,
    accountNumber,
    code,
    createdAt,
    logoUrl,
    accountType,
    phone,
    disabled,
    billingAddress,
    billingContact,
    isTest,
    recentlyBookedShipments = [],
    brokerProfile,
  } = account

  const [lastShipment] = recentlyBookedShipments

  useEffect(() => {
    dispatch(fetchAccount(accountId))
  }, [])

  const {data} = useGetProviderByIdentifierQuery(
    {
      identifier: `MC-${brokerProfile?.mcNumber}`,
    },
    {
      skip: !brokerProfile?.mcNumber,
    },
  )

  const providerUSDOT =
    data?.entities?.find((entity) =>
      entity?.mcNumbers?.includes(brokerProfile?.mcNumber),
    )?.usdot || null

  const {data: closeIoData} = useGetCloseIOQuery(accountId, {
    skip: !accountId || isDev,
  })

  const sortedCloseIoNotes = React.useMemo(() => {
    if (!closeIoData?.notes) {
      return []
    }
    return [...closeIoData.notes].sort((a, b) => {
      if (a.date_updated < b.date_updated) return 1
      if (a.date_updated > b.date_updated) return -1
      return 0
    })
  }, [closeIoData?.notes])

  const billingAddr = addressToString(billingAddress, name)

  // Settings
  const setAccountStatus = (status) => {
    if (status === 'disabled') {
      dispatch(updateAccount({accountId, disabled: true}))
    }
    if (status === 'test') {
      dispatch(updateAccount({accountId, isTest: true, disabled: false}))
    }
    if (status === 'active') {
      dispatch(updateAccount({accountId, isTest: false, disabled: false}))
    }
    setStatusExpanded((isOpen) => !isOpen)
  }

  const isStarred = React.useMemo(() => {
    return (user.adminAccountIds ?? []).includes(accountId)
  }, [user.adminAccountIds, accountId])

  const [isStarring, setStarring] = React.useState(false)
  const toggleStar = async (e) => {
    setStarring(true)

    const adminAccountIds = isStarred
      ? (user.adminAccountIds ?? []).filter((id) => id !== accountId)
      : [...(user.adminAccountIds ?? []), accountId]

    await dispatch(updateUser({adminAccountIds}))

    setStarring(false)
  }

  // Fetch linked MFS customer
  const {data: linkedCustomer = {}} = useGetLinkedCustomerQuery(
    {id: account?.id},
    {skip: !account?.id},
  )
  const {xnAccountId, xnCustomerId} = linkedCustomer

  // Load account

  if (!createdAt) {
    return (
      <Layout>
        <div className="m-auto text-center px-32 py-24">
          <SVG name="loading" className="w-24" />
        </div>
      </Layout>
    )
  }

  return (
    <Layout className="max-w-[1600px]">
      <nav
        className="flex items-center justify-between pt-5 pb-8"
        aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4 m-0">
          <li>
            <div>
              <Link
                to="/accounts"
                className="flex items-center space-x-1 text-gray-800 hover:text-black-500">
                <SVG
                  name="customer"
                  className="flex-shrink-0 h-5 w-5"
                  aria-hidden="true"
                />
                <div>Accounts</div>
              </Link>
            </div>
          </li>
          <li>
            <div className="flex items-center space-x-4">
              <SVG
                name="slash"
                className="flex-shrink-0 h-5 w-5 text-gray-600"
              />
              <div className="ml-4 text-sm font-medium text-black-500">
                {name}
              </div>
            </div>
          </li>
        </ol>
      </nav>

      <div className="flex flex-col-reverse ml:flex-row gap-4">
        <div className="relative rounded-lg pt-2 pb-3 pl-3 pr-12 text-transparent">
          <div className="flex gap-3 items-center">
            <div className="text-32 font-semibold pb-2 text-black-500">
              {name}
            </div>
            <Pressable
              onClick={toggleStar}
              disabled={isStarring}
              className="pb-1">
              <SVG
                name="star"
                className={twMerge(
                  'w-6',
                  isStarred ? 'text-yellow-500' : 'text-gray-700',
                )}
              />
            </Pressable>
          </div>

          <div className="text-14 text-black-100 pb-4 flex gap-6 items-center">
            <div className="relative z-10 flex items-center">
              {accountNumber}
              <QFIconWithCopy icon="copy" text={accountNumber} />
            </div>

            <div>{capitalize(accountType)}</div>

            {accountType === 'broker' && (
              <>
                {brokerProfile?.mcNumber && (
                  <div className="relative z-10 flex items-center">
                    {`MC-${brokerProfile.mcNumber}`}
                    <QFIconWithCopy
                      icon="copy"
                      text={`MC-${brokerProfile.mcNumber}`}
                    />
                  </div>
                )}

                {providerUSDOT && (
                  <div className="relative z-10 flex items-center">
                    {`USDOT ${providerUSDOT}`}
                    <QFIconWithCopy icon="copy" text={providerUSDOT} />
                  </div>
                )}
              </>
            )}

            <div className="relative z-10 flex items-center">
              {accountType === 'broker' && code && (
                <>
                  {code}
                  <QFIconWithCopy icon="copy" text={code} />
                </>
              )}
            </div>
          </div>

          <div className="flex flex-col ml:flex-row gap-2 ml:gap-12 text-14 text-black-500">
            <div className="flex flex-col space-y-1">
              <div className="flex items-center">
                <SVG name="phone" className="w-5 h-5 mr-2 text-gray-700" />
                {phone ? (
                  <a
                    href={`tel:${phone}`}
                    target="_blank"
                    className="font-semibold hover:text-blue-500 relative z-10"
                    rel="noreferrer">
                    {phone}
                  </a>
                ) : (
                  <span className="text-gray-700">no phone</span>
                )}
              </div>
            </div>

            <div className="flex flex-col space-y-1 ml:border-l border-gray-400 ml:pl-12">
              <div className="flex items-center">
                <SVG name="delivery" className="w-5 h-5 mr-2 text-gray-700" />
                <div className="relative z-10 flex items-center">
                  {billingAddr}
                  <QFIconWithCopy icon="copy" text={billingAddr} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center ml:ml-auto">
          <img
            src={logoUrl}
            alt={name}
            className="max-w-[200px] max-h-[100px] border bg-white p-2 border-gray-500 text-black-100 rounded-lg"
          />
        </div>
      </div>

      <Tab.Group>
        <Tab.List className="self-start flex items-center gap-2 pt-8 border-b-2 border-gray-300 w-full">
          <Tab as={Fragment}>
            {({selected}) => (
              <button
                type="button"
                className={`px-2 pb-2 border-b-2 font-bold w-[12.5%] text-left ${
                  selected
                    ? 'text-black-500 border-blue-500'
                    : 'text-black-100 hover:text-black-300 border-gray-300 hover:border-blue-200'
                }`}>
                Overview
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({selected}) => (
              <button
                type="button"
                className={`px-2 pb-2 border-b-2 font-bold w-[12.5%] text-left ${
                  selected
                    ? 'text-black-500 border-blue-500'
                    : 'text-black-100 hover:text-black-300 border-gray-300 hover:border-blue-200'
                }`}>
                Carriers
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({selected}) => (
              <button
                type="button"
                className={`px-2 pb-2 border-b-2 font-bold w-[12.5%] text-left ${
                  selected
                    ? 'text-black-500 border-blue-500'
                    : 'text-black-100 hover:text-black-300 border-gray-300 hover:border-blue-200'
                }`}>
                Shipments
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({selected}) => (
              <button
                type="button"
                className={`px-2 pb-2 border-b-2 font-bold w-[12.5%] text-left ${
                  selected
                    ? 'text-black-500 border-blue-500'
                    : 'text-black-100 hover:text-black-300 border-gray-300 hover:border-blue-200'
                }`}>
                Quotes
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({selected}) => (
              <button
                type="button"
                className={`px-2 pb-2 border-b-2 font-bold w-[12.5%] text-left ${
                  selected
                    ? 'text-black-500 border-blue-500'
                    : 'text-black-100 hover:text-black-300 border-gray-300 hover:border-blue-200'
                }`}>
                Customers
              </button>
            )}
          </Tab>
        </Tab.List>

        <Tab.Panels className="flex-1 flex flex-col">
          <Tab.Panel className="pt-8 flex-1 flex flex-col">
            <div className="w-full lg:flex gap-4 flex-1 mb-8">
              <div className="sm:min-w-[320px]">
                <div className="flex flex-col gap-4">
                  <Card className="border border-gray-400 bg-gray-100 text-black-100">
                    <Card className="flex flex-col text-13 rounded-b-none text-black-500">
                      <div className="rounded-t-lg border-b border-gray-300 ">
                        {!isTest && !disabled && (
                          <button
                            type="button"
                            className="bg-white flex items-center px-3 py-4 rounded-t-lg font-semibold text-17 w-full group"
                            onClick={() => {
                              setStatusExpanded((isOpen) => !isOpen)
                            }}>
                            <span className="w-3 h-3 inline-block rounded-full ml-1.5 mr-2.5 bg-green-500" />
                            <span>Active</span>
                            <SVG
                              name="arrowDown"
                              className="w-6 h-6 text-gray-800 group-hover:text-black-300 ml-auto -mr-1"
                            />
                          </button>
                        )}
                        {isTest && !disabled && (
                          <button
                            type="button"
                            className="bg-white flex items-center px-3 py-4 rounded-t-lg font-semibold text-17 w-full group"
                            onClick={() => {
                              setStatusExpanded((isOpen) => !isOpen)
                            }}>
                            <span className="w-3 h-3 inline-block rounded-full ml-1.5 mr-2.5 bg-orange-500" />
                            <span>Testing</span>
                            <SVG
                              name="arrowDown"
                              className="w-6 h-6 text-gray-800 group-hover:text-black-300 ml-auto -mr-1"
                            />
                          </button>
                        )}
                        {disabled && (
                          <button
                            type="button"
                            className="bg-white flex items-center px-3 py-4 rounded-t-lg font-semibold text-17 w-full group"
                            onClick={() => {
                              setStatusExpanded((isOpen) => !isOpen)
                            }}>
                            <span className="w-3 h-3 inline-block rounded-full ml-1.5 mr-2.5 bg-red-500" />
                            <span>Disabled</span>
                            <SVG
                              name="arrowDown"
                              className="w-6 h-6 text-gray-800 group-hover:text-black-300 ml-auto -mr-1"
                            />
                          </button>
                        )}

                        <Transition
                          show={statusExpanded}
                          enter="transition-all duration-300"
                          enterFrom="opacity-0 max-h-0"
                          enterTo="opacity-100 max-h-[600px]"
                          leave="transition-all duration-300"
                          leaveFrom="opacity-100 max-h-[600px]"
                          leaveTo="opacity-0 max-h-0"
                          unmount={false}
                          className="bg-gray-200 py-1">
                          <Pressable
                            className={`flex items-center px-3 py-3 font-semibold text-17 w-full hover:bg-gray-300 ${
                              !disabled &&
                              'shadow-[-4px_0_0_-2px] shadow-green-500'
                            }`}
                            onClick={() => {
                              setAccountStatus('active')
                            }}>
                            <span className="w-3 h-3 bg-green-500 inline-block rounded-full ml-1.5 mr-2.5" />
                            <span>Active</span>
                          </Pressable>
                          <Pressable
                            className={`flex items-center px-3 py-3 font-semibold text-17 w-full hover:bg-gray-300 ${
                              disabled &&
                              'shadow-[-4px_0_0_-2px] shadow-orange-500'
                            }`}
                            onClick={() => {
                              setAccountStatus('test')
                            }}>
                            <span className="w-3 h-3 bg-orange-500 inline-block rounded-full ml-1.5 mr-2.5" />
                            <span>Testing</span>
                          </Pressable>
                          <Pressable
                            className={`flex items-center px-3 py-3 font-semibold text-17 w-full hover:bg-gray-300 ${
                              disabled &&
                              'shadow-[-4px_0_0_-2px] shadow-red-500'
                            }`}
                            onClick={() => {
                              setAccountStatus('disabled')
                            }}>
                            <span className="w-3 h-3 bg-red-500 inline-block rounded-full ml-1.5 mr-2.5" />
                            <span>Disabled</span>
                          </Pressable>
                        </Transition>
                      </div>
                    </Card>
                    <div className="px-4 py-3 rounded-b-lg">
                      <div className="flex flex-col gap-3">
                        <div className="flex items-center text-black-100">
                          <SVG
                            name="calendar"
                            className="w-5 h-5 mr-2 text-gray-700"
                          />
                          created&nbsp;
                          <Timestamp timestamp={createdAt} />
                        </div>
                        {lastShipment?.bookedAt ? (
                          <div className="flex items-center text-black-100">
                            <SVG
                              name="time"
                              className="w-5 h-5 mr-2 text-gray-700"
                            />
                            booked&nbsp;
                            <Timestamp timestamp={lastShipment.bookedAt} />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </Card>

                  <div className="rounded-lg border border-gray-400 p-3">
                    <div className="flex items-center gap-1 pb-2 text-12 text-black-100 font-semibold">
                      <SVG name="external" className="w-6 h-6 text-gray-900" />
                      <div>Open app as</div>
                    </div>
                    <div className="flex gap-4">
                      <a
                        href={`${config.QF_APP_URL}?activeAccountId=${account.id}`}
                        target="_blank"
                        rel="noreferrer"
                        className="btn blue flex-1 !text-14">
                        {name}
                      </a>

                      {xnCustomerId && (
                        <a
                          href={`${config.QF_APP_URL}/broker/customer/${xnCustomerId}?activeAccountId=${xnAccountId}`}
                          target="_blank"
                          rel="noreferrer"
                          className="shadow-[inset_0_0_0_1px] shadow-blue-600 hover:bg-blue-700 rounded-full flex items-center justify-center text-blue-500 hover:text-white font-bold flex-1 text-14">
                          MFS
                        </a>
                      )}
                    </div>
                  </div>

                  <CreditLimitChart accountId={account?.id} size="default" />

                  <AccountPathfinderCard account={account} />

                  <Divider />

                  <AccountSettings account={account} />
                  <AccountFeatureFlags account={account} />
                </div>
              </div>

              <div className="flex flex-col gap-4 pt-4 sm:pt-0 flex-1">
                <div className="flex flex-col">
                  <div className="rounded-lg border md:h-full bg-gray-300 md:bg-gray-300/60 p-3 pb-0 -mb-3">
                    <AccountIntegrationList accountId={accountId} />
                  </div>
                </div>

                <div className="flex-1 flex flex-row gap-4">
                  <div className="flex flex-col gap-4 w-full max-w-xs">
                    <Card className="bg-gray-300 border border-gray-400 mb-3 max-h-[30vh] overflow-auto">
                      <Card className="px-3 text-13">
                        <div className="flex items-center justify-between border-b border-gray-300 py-3 h-14">
                          <div className="text-black-100 text-12 up font-semibold flex items-center space-x-1">
                            <span>Users</span>
                          </div>
                        </div>
                        <AccountUsers accountId={accountId} />
                      </Card>
                    </Card>

                    <AccountBillingContact contact={billingContact} />
                  </div>

                  <div className="flex-1 flex flex-col">
                    <div className="h-0 grow overflow-auto overscroll-y-contain flex flex-col gap-4">
                      {closeIoData?.lead?.id && (
                        // eslint-disable-next-line jsx-a11y/control-has-associated-label
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`https://app.close.com/lead/${closeIoData.lead.id}`}
                          className="icon flex items-center justify-between sticky top-0 bg-gray-200 pb-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 112 30"
                            className="w-20">
                            <path
                              fill="#000"
                              d="M56.655 20.885a.187.187 0 0 0-.13-.06c-.05-.01-.1.015-.135.045a9.673 9.673 0 0 1-6.475 2.5c-5.19 0-9.41-4.1-9.41-9.14 0-5.04 4.22-9.08 9.41-9.08 2.385 0 4.745.92 6.47 2.53.04.035.085.05.145.05.05 0 .1-.03.135-.07l.75-.96c.06-.08.05-.19-.025-.255a11.25 11.25 0 0 0-7.505-2.87C43.81 3.575 38.87 8.37 38.87 14.26c0 5.89 4.94 10.715 11.015 10.715 2.75 0 5.5-1.08 7.535-2.96a.189.189 0 0 0 .01-.265l-.78-.87.005.005ZM62.695 2.315H61.44a.19.19 0 0 0-.19.19V24.76c0 .105.085.19.19.19h1.255a.19.19 0 0 0 .19-.19V2.505a.19.19 0 0 0-.19-.19ZM74.215 8.915c-4.575 0-7.895 3.37-7.895 8.015 0 4.645 3.32 8.045 7.895 8.045 4.575 0 7.895-3.385 7.895-8.045s-3.32-8.015-7.895-8.015Zm0 14.52c-3.705 0-6.29-2.66-6.29-6.47 0-3.81 2.585-6.47 6.29-6.47s6.29 2.66 6.29 6.47c0 3.81-2.585 6.47-6.29 6.47ZM89.795 15.95c-2.125-.64-3.96-1.19-3.96-3.01 0-1.575 1.39-2.51 3.71-2.51 1.4 0 2.77.385 3.86 1.08.045.03.1.035.15.025a.19.19 0 0 0 .12-.095l.54-1.02a.192.192 0 0 0-.07-.25c-1.37-.835-2.905-1.255-4.565-1.255-2.57 0-5.315 1.08-5.315 4.115s2.69 3.785 5.06 4.525c2.115.66 4.115 1.28 4.06 3.235-.115 2.48-3.075 2.67-3.985 2.67-1.92 0-3.78-.705-5.09-1.93a.175.175 0 0 0-.155-.05.195.195 0 0 0-.135.09l-.57.96a.189.189 0 0 0 .03.23c1.465 1.405 3.605 2.21 5.86 2.21 2.59 0 5.615-1.15 5.615-4.39 0-3.24-2.74-3.91-5.16-4.64v.01ZM109.585 10.96c-1.315-1.34-3.13-2.045-5.25-2.045-4.525 0-7.805 3.385-7.805 8.045s3.285 8.015 7.805 8.015c2.48 0 4.68-.905 6.2-2.55a.185.185 0 0 0 0-.25l-.72-.81a.205.205 0 0 0-.14-.065c-.045.015-.105.02-.14.06-1.235 1.355-3.06 2.1-5.14 2.1-3.51 0-6-2.395-6.25-5.985h13.45c.1 0 .185-.08.19-.185.05-2.59-.735-4.84-2.2-6.335v.005Zm-11.4 5.03c.37-3.335 2.82-5.565 6.155-5.565 3.335 0 5.605 2.175 5.915 5.565h-12.07Z"
                            />
                            <path
                              fill="#4EC375"
                              d="M16 23.505c-8.97 0-16-3.735-16-8.5 0-4.765 7.03-8.5 16-8.5 8.97 0 16 3.735 16 8.5 0 4.765-7.03 8.5-16 8.5Zm0-14.5c-6.385 0-13.5 2.465-13.5 6s7.115 6 13.5 6 13.5-2.465 13.5-6-7.115-6-13.5-6Z"
                            />
                            <path
                              fill="#1463FF"
                              d="M21.415 29.525c-4.01 0-9.14-3.965-12.78-10.27-2.065-3.575-3.32-7.35-3.54-10.635-.24-3.6.79-6.25 2.9-7.47 2.11-1.22 4.925-.785 7.92 1.22 2.735 1.835 5.375 4.81 7.44 8.385 4.485 7.77 4.765 15.725.64 18.105-.78.45-1.65.665-2.585.665h.005Zm-10.79-26.55c-.52 0-.985.11-1.375.34-1.225.705-1.83 2.58-1.66 5.14.195 2.91 1.335 6.305 3.21 9.555 3.19 5.53 8.885 10.46 11.945 8.69 3.06-1.77 1.64-9.16-1.555-14.69-1.875-3.25-4.245-5.935-6.665-7.555-1.45-.97-2.8-1.47-3.905-1.47l.005-.01Z"
                            />
                            <path
                              fill="#FFBC00"
                              d="M10.62 29.54c-.955 0-1.835-.225-2.62-.68-2.11-1.22-3.145-3.87-2.9-7.47.22-3.285 1.475-7.06 3.54-10.635 4.485-7.77 11.235-11.99 15.36-9.61 4.125 2.385 3.845 10.335-.64 18.105-2.065 3.575-4.705 6.55-7.44 8.385-1.88 1.26-3.69 1.9-5.3 1.9v.005ZM21.31 2.945c-3.18 0-7.765 4.305-10.51 9.055-1.875 3.25-3.015 6.64-3.21 9.555-.17 2.56.435 4.43 1.66 5.14 1.225.705 3.145.295 5.28-1.135 2.425-1.625 4.79-4.31 6.665-7.555 3.19-5.53 4.615-12.925 1.555-14.69a2.818 2.818 0 0 0-1.44-.365v-.005Z"
                            />
                            <path
                              fill="#4EC375"
                              d="M16 21.005c-6.385 0-13.5-2.465-13.5-6H0c0 4.765 7.03 8.5 16 8.5v-2.5Z"
                            />
                            <path
                              fill="#1463FF"
                              d="M16.575 6.08c-.67-.62-1.355-1.17-2.045-1.635-.68-.455-1.34-.81-1.96-1.055l.83-2.36c.82.315 1.665.76 2.525 1.34.905.61 1.805 1.34 2.675 2.18l-2.02 1.53h-.005ZM18.58 26.245c-1.08-.58-2.205-1.445-3.295-2.49L13.37 25.42c1.12 1.085 2.27 1.975 3.41 2.645l1.805-1.82h-.005Z"
                            />
                            <path
                              fill="#4EC375"
                              d="M27.52 11.945C24.96 10.125 20.315 9 16 9V6.5c5.025 0 9.44 1.17 12.355 3.04l-.835 2.405Z"
                            />
                          </svg>
                          <SVG name="external" />
                        </a>
                      )}

                      {closeIoData?.csm && (
                        <div className="px-3 pb-3">
                          <span className="pr-2 text-14 text-black-100">
                            CSM:
                          </span>
                          {closeIoData.csm}
                        </div>
                      )}

                      {closeIoData?.lead?.description && (
                        <div className="px-3 pb-3">
                          {closeIoData?.lead?.description}
                        </div>
                      )}

                      {sortedCloseIoNotes.map((note) => (
                        <div
                          key={note.id}
                          className="rounded-md bg-gray-300/80 p-3 text-13">
                          <div className="text-11 text-black-300 flex justify-between pb-2">
                            <div className="font-bold">
                              {note.updated_by_name}
                            </div>
                            <Timestamp timestamp={note.date_updated} />
                          </div>
                          <HTML>{note.note_html}</HTML>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab.Panel>
          <Tab.Panel className="pt-4">
            <ProviderIntegrationList accountId={accountId} />
          </Tab.Panel>
          <Tab.Panel />
          <Tab.Panel />
          <Tab.Panel />
        </Tab.Panels>
      </Tab.Group>
    </Layout>
  )
}
